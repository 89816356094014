const financialChargesModalityData = [
    {
        label: "CDI (AM) +",
        value: "CDI"
    },
    {
        label: "IOF - 0,38%",
        value: "IOF"
    },
    {
        label: "Pro-rata",
        value: "PRO_RATA"
    },
];

export default financialChargesModalityData;